html, body, #root {
    /* background-color: #E4E9EE; */
  }

  .rbc-event-label {
    /* font-weight: bold; */
    display: none !important;
  }


  /* .rbc-agenda-view {
    padding: 10px;
  }
  
  .rbc-agenda-view h3 {
    font-size: 1.5em;
    margin-bottom: 10px;
  } */

    .custom-week-view {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
  
    .week-header {
      display: flex;
      justify-content: space-between;
      padding: 5px;
      background-color: #f8f9fa;
    }
  
    .week-body {
      display: flex;
      justify-content: space-between;
      height: 100%;
    }
  
    .day-column {
      flex: 1;
      border: 1px solid #dee2e6;
      padding: 5px;
    }
  
    .department-box {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      color: #fff;
      font-size: 12px;
      border-radius: 4px;
    }


@media print {
  .card {
    border: 2px solid black !important;
  }

  .navbar {
    background-color: darkblue !important;
  }

  body {
    margin: 20mm;
    font-size: 30px;
  }
}